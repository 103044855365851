import { BillingCountResponse, ServiceUnitRenderResponse, dateModel,TrainingLinkDateModel ,TrainingLinksResponse,UpcomingAppointmentsDataModel,UpcomingAppointmentsResponse,UpcomingComplianceCountDataModal,UpcomingComplianceCountResponse,UpcomingComplianceDataModal,UpcomingComplianceResponse} from "src/dataModels/dashboardResponseModel";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";
import { GenericResponse } from "src/dataModels/authSetModels";
import { TimeClockedByIdResponse, UpdateStaffTimeClockPayload } from "src/dataModels/clinicResponseModels";

export const dashboardServices = {

    clientCount: (data) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLIENT_COUNT}?siteId=${data?.siteId}`);
    },
    diagnosisCount: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLIENT_DIAGNOSIS_COUNT}`);
    },
    serviceUnitsRender: (data: dateModel) => {
        return customAxios.get<ServiceUnitRenderResponse>(`${API_ENDPOINTS.GET_SERVICE_UNITS_RENDER}${data?.pastYearDate}&toDate=${data?.currentDate}&siteId=${data?.siteId}`);
    },

    billingCounts: (data: dateModel) => {
        return customAxios.get<BillingCountResponse>(`${API_ENDPOINTS.GET_BILLING_COUNTS}${data?.pastYearDate}&toDate=${data?.currentDate}&siteId=${data?.siteId}`);
    },

    getStaffClockStatus: () => {
        return customAxios.get<GenericResponse>(`${API_ENDPOINTS.GET_STAFF_CLOCK_STATUS}`);
    },
    insertClockStatus: () => {
        return customAxios.post<GenericResponse>(`${API_ENDPOINTS.CREATE_NEW_TIMECLOCK}`, { "isCustomClock": false });
    },
    getTimeClockById: (id: Number) => {
        return customAxios.get<TimeClockedByIdResponse>(`${API_ENDPOINTS.GET_TIME_CLOCK_BY_ID}${id}`);
    },
    updateStaffTimeClockById: (data: UpdateStaffTimeClockPayload) => {
        return customAxios.put<any>(`${API_ENDPOINTS.UPDATE_STAFF_TIME_CLOCK}`, data);
    },
    clientByGenderCount: (data) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLIENT_BY_GENDER_COUNT}?siteId=${data?.siteId}`);
    },
    getTimeClockArchiveById: (staffTimeClockId: Number) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_TIME_CLOCK_ARCHIVE_BY_ID}?staffTimeClockId=${staffTimeClockId}`)
    },

    getTrainingLinks : (params:TrainingLinkDateModel) =>{
        return customAxios.get<TrainingLinksResponse>(`${API_ENDPOINTS.GET_TRAINING_LINKS}?startDate=${params?.startDate}&endDate=${params?.endDate}`) 
    },
    getDaysSinceLastSeenExcel : (data:any) =>{
        return customAxios.post<any>(`${API_ENDPOINTS.DAYS_SINCE_CLIENTS_LAST_SEEN_EXCEL}`, data);
    },
    expiringAuthorizationsExcel : (data:any) =>{
        return customAxios.post<any>(`${API_ENDPOINTS.EXPIRING_AUTHORIZATIONS_EXCEL}`,data) 
    },
    getUpcomingAppointments : (params:UpcomingAppointmentsDataModel) =>{
        return customAxios.get<UpcomingAppointmentsResponse>(`${API_ENDPOINTS.GET_UPCOMING_APPOINTMENTS}?startDate=${params?.startDate}&endDate=${params?.endDate}`) 
    },
    getUpcomingComplianceCount : (params:UpcomingComplianceCountDataModal[]) =>{
        return customAxios.post<any , UpcomingComplianceCountResponse>(`${API_ENDPOINTS.GET_UPCOMING_COMPLIANCE_COUNT}`, params);
    },
    getUpcomingCompliance : (params:UpcomingComplianceDataModal) =>{
        return customAxios.post<any , UpcomingComplianceResponse>(`${API_ENDPOINTS.GET_UPCOMING_COMPLIANCE}`, params);
    },
    getInsuranceTypes: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_INSURANCE_TYPE}`);
    },
}