import React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { forwardRef, useRef, useImperativeHandle } from "react"

export interface SealDocumenProps {
  onClose?,
  title?,
  message?,
  handleDelete?,
  activeType?,
  handleReactive?
}


const SealDocumentModal = (props: SealDocumenProps) => {
  const { onClose,
    title,
    message,
    handleDelete,
    activeType,
    handleReactive } = props;

  return (
    <Dialog onClose={onClose} title={`Seal ${title}`} className='small-dialog xs-modal width_90 blue_theme'  >
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        {message}
      </p>
      <DialogActionsBar>
        {activeType ? (
          <button
            className="submitButon" style={{height:"27.6px"}}
            onClick={handleReactive}
          >
            Yes
          </button>
        ) : (
          <button
            className="submitButon " style={{height:"27.6px"}}
            onClick={handleDelete}
          >
            Yes
          </button>
        )}
        <button
          className="  sealBtn cancelBtn"
          onClick={onClose}
        >
          No
        </button>

      </DialogActionsBar>
    </Dialog>
  )
}

export default SealDocumentModal