import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { clientAuditLogListChangedAction, clientLedgerBalanceChanged, getClientComplianceList, getClientMedicationByClientId, getCpAccAccess, loaderAction, loadingInClient, saveAttachedDocForCompliance, saveBillingClaims, saveClientAuditLogList, saveClientAuditLogMetaData, saveClientComplianceList, saveClientLedgerByClientId, saveClientLedgerById, saveClientLedgerPaymentTypes, saveClientListChangedBoolean, saveClientMedicationByClientId, saveClientMedicationById, saveClientStatus, saveClinicFlag, saveCpAccountAccess, saveCreateResponse, saveCurrentLedgerBalance, saveDocLoadingState, saveDuplicateClients, saveMultipleClientList, saveReviewGoalByGoalId, saveTpStaffTypeDll, saveUnreadCount, storeAssignFlagToClient,saveSocialDeterminantsResponse } from '../actions';
import { clientServices } from 'src/services/clientServices';
import { ClientBillingClaimResponse, ClientComplianceListResponse, CompleteComplianceResponse, ResponseMultiClientList, clientStatusResponse, clinicFlagResponse, unreadCountResponse } from 'src/dataModels/clientResponse';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { GetTpStaffTypesResponse, ReviewGoalResponse } from 'src/dataModels/clinicResponseModels';




export function* clientBillingClaimSaga() {
  yield takeLatest(TYPES.GET_CLIENT_BILLING_CLAIMS, claimWorker);
}

function* claimWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientBillingClaimResponse = yield clientServices.billingClaims(param['payload']);
    yield put(saveBillingClaims(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getClientComplianceListSaga() {
  yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE_LIST, getClientComplianceListWorker);
}

function* getClientComplianceListWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientComplianceListResponse = yield clientServices.clientCompliance(param['payload']);
    yield put(loaderAction(false));
    yield put(saveClientComplianceList(response))
  } catch (err) {
  }
}

export function* completeComplianceSaga() {
  yield takeLatest(TYPES.COMPLETE_COMPLIANCE, completeComplianceWorker);
}

function* completeComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: CompleteComplianceResponse = yield clientServices.completeCompliance(param['payload']);
    NotificationManager.success(NOTIFICATION_MESSAGE.COMPLETE_COMPLIANCE);
    yield put(getClientComplianceList(param['payload'].clientId))
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* deleteManuallyCompletedComplianceSaga() {
  yield takeLatest(TYPES.DELETE_MANUALLY_COMPLETED_COMPLIANCE, deleteManuallyCompletedComplianceWorker);
}

function* deleteManuallyCompletedComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.deleteManuallyCompletedCompliance(param['payload']['complianceId']);
    yield put(loaderAction(false));
    yield put(getClientComplianceList(param['payload'].clientId))
    NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_COMPLETE_COMPLIANCE);

  }
  catch (err) {
  }
}

export function* getClinicFlagSaga() {
  yield takeLatest(TYPES.GET_CLINIC_FLAG, getClinicFlagWorker);
}

function* getClinicFlagWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: clinicFlagResponse = yield clientServices.getClinicFlag();
    yield put(loaderAction(false));
    yield put(saveClinicFlag(response))


  }
  catch (err) {
  }
}

export function* saveAssignFlagToClientSaga() {
  yield takeLatest(TYPES.ASSIGN_FLAG_TO_CLIENT, saveAssignFlagToClientWorker);
}

function* saveAssignFlagToClientWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveClinicFlag(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_FLAG_TO_CLINET);
    yield put(storeAssignFlagToClient(response))

  }
  catch (err) {
  }
}

export function* getClientStatusSaga() {
  yield takeLatest(TYPES.GET_CLIENT_STATUS, getClientStatusWorker);
}

function* getClientStatusWorker() {
  try {
    yield put(loaderAction(true));
    const response: clientStatusResponse = yield clientServices.getClientStatus();
    yield put(loaderAction(false));
    yield put(saveClientStatus(response))
  }
  catch (err) {
  }
}

export function* saveSelectedClientStatusSaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_STATUS, saveSelectedClientStatusWorker);
}

function* saveSelectedClientStatusWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveSelectedClientStatus(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.CHANGE_CLIENT_STATUS_OF_SELECTED_CLIENTS);
  }
  catch (err) {
  }
}
export function* saveSelectedClientEligibilitySaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_ELIGIBILITY, saveSelectedClientEligibilityWorker);
}

function* saveSelectedClientEligibilityWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveSelectedClientEligibility(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_ELIGIBILITY);
  }
  catch (err) {
  }
}

export function* getUnreadCountSaga() {
  yield takeLatest(TYPES.GET_UNREAD_COUNT, getUnreadCountWorker);
}

function* getUnreadCountWorker() {
  try {
    yield put(loaderAction(true));
    const response: unreadCountResponse = yield clientServices.getUnreadCount();
    yield put(loaderAction(false));
    yield put(saveUnreadCount(response))
  }
  catch (err) {
  }
}

export function* getMultiClientListSaga() {
  yield takeLatest(TYPES.GET_MULTIPLE_CLIENTS, getmultiClientList);
}

function* getmultiClientList(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: ResponseMultiClientList = yield clientServices.getMultiClientList(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveMultipleClientList(response.resultData))
  }
  catch (err) {
  }
}

export function* getTpStaffTypesDllSaga() {
  yield takeLatest(TYPES.GET_TP_STAFF_TYPE_DLL, getTpStaffTypesDllList);
}

function* getTpStaffTypesDllList(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GetTpStaffTypesResponse = yield clientServices.getTpStaffType();
    yield put(loaderAction(false));
    yield put(saveTpStaffTypeDll(response.resultData))
  }
  catch (err) {
  }
}

export function* insertReviewGoalSaga() {
  yield takeLatest(TYPES.INSERT_REVIEW_GOAL, insertReviewGoalWorker);
}

function* insertReviewGoalWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.insertReviewGoal(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_REVIEW_GOAL);
  }
  catch (err) {
  }
}

export function* getReviewGoalByGoalIdSaga() {
  yield takeLatest(TYPES.GET_REVIEW_GOAL_BY_GOAL_ID, getReviewGoalByGoalId);
}

function* getReviewGoalByGoalId(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ReviewGoalResponse = yield clientServices.getReviewGoalByGoalId(param['payload']);
    yield put(loaderAction(false));
    yield put(saveReviewGoalByGoalId(response.resultData))
  }
  catch (err) {
  }
}

export function* deleteReviewGoalSaga() {
  yield takeLatest(TYPES.DELETE_REVIEW_GOAL, deleteReviewGoal);
}

function* deleteReviewGoal(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.deleteReviewGoal(param['payload']);
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* checkDuplicateClientSaga() {
  yield takeLatest(TYPES.CHECK_DUPLICATE_CLIENTS, checkDuplicateClientWorker);
}

function* checkDuplicateClientWorker(param: any) {
  try {
    // yield put(loaderAction(true));
    const response =yield clientServices.checkDuplicateClient(param['payload']);
    if(response && response.resultData)
    yield put(saveDuplicateClients(response.resultData));
  }
  catch (err) {
  }
}

export function* getClientLedgerByClientIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_CLIENT_ID, getClientLedgerByClientIdWorker);
}

function* getClientLedgerByClientIdWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.getClientLedgerByClientId(param['payload']);
    if(response && response.resultData) {
      yield put(clientLedgerBalanceChanged(true))
    }
    yield put(saveClientLedgerByClientId(response.resultData));
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}

export function* insertClientLedgerRecordSaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_LEDGER_RECORD, insertClientLedgerRecordWorker);
}

function* insertClientLedgerRecordWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertClientLedgerRecord(param['payload']);
    if(response && response.resultData) {
      yield put(saveClientListChangedBoolean(true));
      yield put(loaderAction(false));
    }
  }
  catch (err) {
  }
}

export function* deleteClientLedgerRecordSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_LEDGER_RECORD, deleteClientLedgerRecordWorker);
}

function* deleteClientLedgerRecordWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.deleteClientLedgerRecordWithId(param['id']);
    if(response && response?.resultData) {
      yield put(saveClientListChangedBoolean(true));
    }
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}


export function* getPaymentTypesClientLedgerSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_PAYMENT_TYPES, getPaymentTypesClientLedgerWorker);
}

function* getPaymentTypesClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerPaymentTypes(param['isActive']);
    yield put(saveClientLedgerPaymentTypes(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientLedgerByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_ID, getClientLedgerByWorker);
}

function* getClientLedgerByWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerById(param['id']);
    yield put(saveClientLedgerById(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getCurrentLedgerBalanceSaga() {
  yield takeLatest(TYPES.GET_CURRENT_LEDGER_BALANCE, getCurrentLedgerBalanceWorker);
}

function* getCurrentLedgerBalanceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerCurrentBalance(param['payload']);
    yield put(saveCurrentLedgerBalance(response?.resultData?.currentBalance));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* updateClientLedgerSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_LEDGER, updateClientLedgerWorker);
}

function* updateClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateClientLedger(param['payload']);
    if(response && response.resultData) {
      yield put(saveClientListChangedBoolean(true))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientAuditSaga() {
  yield takeLatest(TYPES.GET_CLIENT_AUDIT_LOG_LIST, getClientAuditWorker);
}

function* getClientAuditWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientAuditLogList(param['payload']);
    if (response.statusCode === 200) {
      yield put(saveClientAuditLogList(response.resultData));
      yield put(saveClientAuditLogMetaData(response.metaData));
      yield put(clientAuditLogListChangedAction(false));
  }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}


export function* getDocForComplianceSaga() {
  yield takeLatest(TYPES.GET_COMPLIANCE_ATTACHED_DOC_LIST, fetchComplianceDocWorker);
}

function* fetchComplianceDocWorker(param: any) {
  try {
    const response = yield clientServices.getDocumentsForCompliance(param['payload']);
    if(response && response?.resultData) {
      yield put(saveAttachedDocForCompliance(response?.resultData));
    }
  }
  catch (err) {
  }
}


export function* getClientPortalAccountAccessSaga() {
  yield takeLatest(TYPES.GET_CP_ACC_ACCESS_LIST, fetchCPaccessWorker);
}

function* fetchCPaccessWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.getClientAccessAccount();
    if(response && response?.resultData) {
      yield put(saveCpAccountAccess(response?.resultData));
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* createAccForCPSaga() {
  yield takeLatest(TYPES.CREATE_CP_ACC_ACCESS, createAccWorker);
}

function* createAccWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.createClientPortalAccessAccount(param['payload']);
    if(response && response?.status) {
      yield put(saveCreateResponse(200));
      NotificationManager.success("New Account for Client portal is added");
      yield put(getCpAccAccess());
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* updateAccForCPSaga() {
  yield takeLatest(TYPES.UPDATE_CP_ACC_ACCESS, updateAccWorker);
}

function* updateAccWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.updateClientPortalAccess(param['payload']);
    if(response && response?.status) {
      NotificationManager.success("Account updated successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* getAccCPDetailSaga() {
  yield takeLatest(TYPES.GET_CP_ACC_ACCESS_DETAIL, detailAccWorker);
}

function* detailAccWorker(param: any) {
  try {
    const response = yield clientServices.getDetailCpAccountAccessDetail(param['payload']);
    if(response && response?.resultData) {
      yield put(getCpAccAccess());
    }
  }
  catch (err) {
  }
}

export function* resetAccPwdSaga() {
  yield takeLatest(TYPES.RESET_CP_ACCESS_PWD, resetWorker);
}

function* resetWorker(param: any) {
  try {
    const response = yield clientServices.resetPasswordForCpAccess(param['payload']);
    if(response && response?.resultData) {
      NotificationManager.success("Password reset email has been sent");
    }
  }
  catch (err) {
  }
}

export function* getClientMedicationByClientIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MEDICATION_BY_CLIENT_ID, getClientMedicationByClientIdWorker);
}

function* getClientMedicationByClientIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientMedicationByClientId(param['payload']);
    if(response && response.statusCode === 200) {
      yield put(saveClientMedicationByClientId(response?.resultData));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}export function* getClientMedicationByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MEDICATION_BY_ID, getClientMedicationByIdWorker);
}

function* getClientMedicationByIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientMedicationById(param['payload']);
    yield put(saveClientMedicationById(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}export function* deleteClientMedicationSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_MEDICATION, deleteClientMedicationWorker);
}

function* deleteClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const { deleteMedicateId } = param['payload'];
    const { selectedClientId } = param['payload'];
    const response = yield clientServices.deleteClientMedication(deleteMedicateId);
    if(response && response.statusCode === 200) {
      const data = {
        id: selectedClientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}export function* updateClientMedicationSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_MEDICATION, updateClientMedicationWorker);
}

function* updateClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateClientMedication(param['payload']);
    if(response && response.statusCode === 200) {
      const data = {
        id: param['payload']?.clientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }    
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}export function* insertClientMedicationSaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_MEDICATION, insertClientMedicationWorker);
}

function* insertClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertClientMedication(param['payload']);
    if(response && response.statusCode === 200) {
      const data = {
        id: param['payload']?.clientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* insertSocialDeterminantsSaga() {
  yield takeLatest(TYPES.SAVE_SOCIAL_DETERMINANTS, insertSocialDeterminantsWorker);
}

function* insertSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertSocialDeterminants(param['payload']);
    if(response && response?.status) {
      NotificationManager.success("Social Determinants Save successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* getSocialDeterminantsSaga() {
  yield takeLatest(TYPES.GET_SOCIAL_DETERMINANTS, getSocialDeterminantsWorker);
}

function* getSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getSocialDeterminants(param['payload']);
    yield put(saveSocialDeterminantsResponse(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* updateSocialDeterminantsSaga() {
  yield takeLatest(TYPES.UPDATE_SOCIAL_DETERMINANTS, updateSocialDeterminantsWorker);
}

function* updateSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateSocialDeterminant(param['payload']);
    if(response && response?.status) {
      NotificationManager.success("Social Determinants Update successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}