import React from 'react'
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";


interface ItemModel {
    id?: number
    name: string
    icon?: number
}

interface propsModel {
    items: ItemModel[],
    text?: string,
    onChange: any,
    size?: string | any,

}

function CustomSplitButton({ items, text, onChange, size }: propsModel) {
    return (
        <div className="example-col">
            <SplitButton  text={text} onItemClick={onChange} size={size} themeColor={"primary"} fillMode={"outline"} style={{ height: 35 }}>
                {items.map((item) => (
                    <SplitButtonItem key={item.id} text={item.name} />
                ))}
            </SplitButton>
        </div>
    )
}

export default CustomSplitButton