import { Chip } from "@progress/kendo-react-buttons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useBirthDateCalculator from "src/cutomHooks/birth-date-calculate/birth-date-calculate";
import { renderErrors } from "src/helper/error-message-helper";
import { GET_CLIENT_DETAILS, GET_CLIENT_INSURANCE, GET_CLIENT_PRIVATE_PAY_STATUS } from "src/redux/actions/types";
import { ClientService } from "src/services/clientService";
import dummyImg from "../../../../assets/images/dummy-img.png";
import CustomSkeleton from "../../../../control-components/skeleton/skeleton";
import { MaskFormatted } from "../../../../helper/mask-helper";

const ClientHeader = () => {
  const [loading, setLoading] = useState(false);
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const currentInsurance = useSelector((state) => state.currentInsuranceDetails);
  const clientDetail = useSelector((state) => state.clientDetails);
  const profilePic = useSelector((state) => state.getClientProfileImg);
  const clientFlags = useSelector((state) => state.clientFlagsReducer);
  const isPrivatePay = useSelector((state) => state.clientPrivatePay);
  const [calculatedAge, handleAge] = useBirthDateCalculator();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!clientDetail|| !clientDetail.fName) {
      getClientDetail()
    }
    if (!currentInsurance) {
      getCurrentInsurance()
    }
  }, [clientDetail, currentInsurance])

  useEffect(() => {
    if (clientDetail?.dob) {
      handleAge(clientDetail.dob);
    }
  }, [clientDetail, handleAge]);

  const getClientDetail = async () => {
    setLoading(true);
    await ClientService.getClientDetail(selectedClientId)
      .then((result) => {
        let clientDetail = result.resultData;
        dispatch({
          type: GET_CLIENT_DETAILS,
          payload: result.resultData,
        });
        dispatch({
          type: GET_CLIENT_PRIVATE_PAY_STATUS,
          payload: clientDetail?.isPrivatePay,
        });
      })
      .catch((error) => {
        renderErrors(error.message);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const getCurrentInsurance = async () => {
    setLoading(true);
    await ClientService.getClientCurrentInsurance(selectedClientId)
      .then((result) => {
        dispatch({
          type: GET_CLIENT_INSURANCE,
          payload: result.resultData,
        });
      })
      .catch((error) => {
        renderErrors(error.message);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <div className="client-profile">
      <div className="client-profileheader profile-box-show">
        <div className="row align-items-start justify-content-between">
          <div className="col-xxl-6 col-lg-6  mb-xxl-0 d-flex flexWrapperResponsive align-items-center">
            <div className="inner-uploadimg">
              {profilePic ? (
                <img
                  src={"data:image/png;base64," + profilePic.clinicLogo}
                  alt="profilePic"
                />
              ) : (
                <div>
                  <img src={dummyImg} alt="dummy" />
                </div>
              )}
            </div>
            {/* </div>
          <div className="col-xxl-5 col-lg-6 mb-3 mb-xxl-0 mt-4 mt-md-0"> */}
            <div className="client_details w-100">
              <h4 className="address-title text-theme my-2">
                {clientDetail?.fName ? (
                  clientDetail?.fName + " " + clientDetail?.lName
                ) : (
                  <CustomSkeleton shape="text" />
                )}
              </h4>
              <ul className="list-unstyled pl-0 details-info mb-0">
                <li className="d-flex mb-2 flexGapBigger align-items-baseline flexWrapperResponsive">
                  <div className="d-flex mb-2 flexGap col-md-6 col-sm-12 p-0 flexWrapperResponsive">
                    <p className=" mb-0  px-0 f-14 fw-500">DOB:</p>
                    <p className=" mb-0  px-0 f-14">
                      {clientDetail?.dob ? (
                        moment(clientDetail?.dob).format("M/D/YYYY")
                      ) : (
                        <CustomSkeleton shape="text" />
                      )}{" "}
                      {clientDetail?.dob || calculatedAge ? (
                        `(${calculatedAge}  years)`
                      ) : (
                        <CustomSkeleton shape="text" />
                      )}
                    </p>
                  </div>

                  <div className="d-flex mb-2 flexGap col-md-6 col-sm-12 p-0 flexWrapperResponsive">
                    <p className=" mb-0  px-0 f-14 fw-500">Phone:</p>
                    <p className=" mb-0  px-0 f-14">
                      {!clientDetail ? (
                        <CustomSkeleton shape="text" />
                      ) : (
                        MaskFormatted(clientDetail?.homePhone, "(999) 999-9999")
                      )}
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-2 flexGapBigger align-items-baseline flexWrapperResponsive">
                  <div className="d-flex mb-2 flexGap col-md-6 col-sm-12 p-0 flexWrapperResponsive">
                    <p className=" mb-0  px-0 f-14 fw-500">Email: </p>
                    <p className=" mb-0  px-0 f-14">
                      {!clientDetail ? (
                        <CustomSkeleton shape="text" />
                      ) : (
                        clientDetail?.email
                      )}
                    </p>
                  </div>
                  <div className="d-flex mb-2 flexGap col-md-8 col-sm-12 p-0 flexWrapperResponsive">
                    <p className=" mb-0  px-0 f-14 fw-500">Insurance:</p>
                    {
                      <p className=" mb-0  px-0 f-14">
                        {isPrivatePay && currentInsurance === null
                          ? "Private/Self Pay"
                          : currentInsurance === null
                            ? ""
                            : currentInsurance.insuranceName}
                      </p>
                    }
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xxl-5 col-lg-4  mb-xxl-0">
            <div className="profiles-tags">
              {clientFlags.length > 0 &&
                clientFlags.map((obj) => (
                  <Chip
                    text={obj.flagName}
                    key={obj.id}
                    value="chip"
                    rounded={"large"}
                    fillMode={"solid"}
                    size={"medium"}
                    style={{
                      marginRight: 5,
                      backgroundColor: obj.color,
                      marginBottom: 10,
                      color: "#ffffff",
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientHeader;
