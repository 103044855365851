import moment from "moment";
import {
  convertFromUtcDateTime,
  convertFromUtcDateToDateOnly,
  convertFromUtcTimeToTimeOnly,
  convertToUtcTime,
} from "../../util/utility";

export const timeRecordingTypeEnum = {
  notRequired: 1,
  actual: 2,
  duration: 3,
  shift: 4,
};

const format = "hh:mm A";

function formatTime(data) {
  let { recordingMethodId, startTime, endTime, shiftName, totalMinutes } = data;
  if (recordingMethodId) {
    switch (recordingMethodId) {
      case timeRecordingTypeEnum.actual:
        if (startTime && endTime) {
          return `${moment(startTime).format(format)} - ${moment(
            endTime
          ).format(format)}`;
        }
        break;
      case timeRecordingTypeEnum.shift:
        if (shiftName) {
          return `${shiftName}`;
        }
        break;
      case timeRecordingTypeEnum.duration:
        if (totalMinutes != null) {
          return `${totalMinutes} mins`;
        }
        break;
    }
  }
  return "-";
}
function DiffrenceTime(data) {
  let { recordingMethodId, startTime, endTime } = data;
  if (recordingMethodId) {
    var ms = moment(endTime).diff(moment(startTime));
    var days = moment.duration(ms);
    var hrs_num = Math.floor(days.asHours());
    var hrs = hrs_num > 0 ? hrs_num + " hr " : "";
    var m = moment.utc(ms).format("mm");
    m = parseInt(m) > 0 ? + m + " mins" : "";
    return (
      hrs + m
    );
  }
  return "-";
}

function parseFullName(fname, mname, lname) {
  let clientName = "";

  if (fname) {
    clientName += lname;
  }
  if (lname) {
    clientName += ", " + fname;
  }

  return clientName;
}

function parseDocumentListItem(data) {
  let clientName = parseFullName(
    data.clientFName,
    data.clientMName,
    data.clientLName
  );
  let authorName = data.staffFName + " " + data.staffLName;
  return {
    ...data,
    documentTemplateName: data.templateName,
    serviceId: data?.serviceId,
    id: data.documentId,
    clientName: clientName,
    serviceName: data.service || data.serviceName,
    serviceNameTemp: data.serviceName,
    docTemplateName: data.documentTemplateName,
    clientNameDoc: data.clientName,
    createdBy: data.createdBy,
    clientId: data.clientId,
    _clientId: data._clientId,
    dateOfBirth: data.dateOfBirth,
    diagnosis: data.diagnosis,
    isLocked: data.isLocked,
    isSigned: data.isSigned,
    lockedByStaff: data.lockedByStaff,
    numUnits: data.numUnits,
    primaryInsurance: data.primaryInsurance,
    serviceRate: data.serviceRate,
    amtBilled: data.amtBilled,
    custAuthId: data.custAuthId,
    rating: data.rating,
    isNoteReviewed: data.isNoteReviewed,
    clientDiagnosisId: data.clientDiagnosisId,
    docStatusId: data.docStatusId,
    docStatus: data.docStatus,
    billingStatusName: data.billingStatusName,
    billingStatusId: data.billingStatusId,
    isDocumentAlreadyBilled: data.isDocumentAlreadyBilled,
    specificPlaceOfService: data.specificPlaceOfService,
    // serviceDateStr: convertFromUtcDateToDateOnly(data.serviceDate),
    serviceDate: data.serviceDate,
    serviceDateStr: data.serviceDate,
    serviceDateObj: moment(data.serviceDate).format('M/D/YYYY'),
    documentTemplateId:data.documentTemplateId,
    utcDateCreatedStr: convertFromUtcDateToDateOnly(data.utcDateCreated),
    utcDateCreatedObj: new Date(
      convertFromUtcDateToDateOnly(data.utcDateCreated)
    ),
    placeOfServiceStr: data.placeOfServiceList.map(
      (y) => ` ${y.placeOfServiceName}`
    ),
    placeOfSerCode: data.placeOfServiceList.map((y) => ` ${y.locCode}`),
    timeStr: data.recordingMethodId ? formatTime(data) : "-",
    authorName: authorName,
    timeDiff: data.recordingMethodId == timeRecordingTypeEnum.actual ? DiffrenceTime(data) : data.recordingMethodId == timeRecordingTypeEnum.duration ? totHoursAndMinutes(data.totalMinutes) : "-",
    riskAssessment: data?.riskAssessment,
    utcExpectedDeletionDateStr: convertFromUtcDateToDateOnly(data.expectedDeletionDate),
    ebpCodeId:data.ebpCodeId,
    ebpPracticeName:data.ebpPracticeName,
    ebpTrackingCode:data.ebpTrackingCode
  };
}

function parseDocumentDraftListItem(serverDoc) {
  let clientName = serverDoc.draftData.clientName;
  if (serverDoc.draftData.clients && serverDoc.draftData.clients.length > 0) {
    clientName = "";
    for (const client of serverDoc.draftData.clients) {
      if (clientName != "") {
        clientName += ", ";
      }
      clientName += client.clientName;
    }
  }

  return {
    ...serverDoc.draftData,
    documentTemplateName: serverDoc.draftData.templateName
      ? serverDoc.draftData.templateName
      : serverDoc.draftData.documentTemplateName,
    draftId: serverDoc.draftData.draftId,
    documentTemplateId:serverDoc.draftData.documentTemplateId,
    clientName: clientName,
    serviceName: serverDoc.draftData.serviceName
      ? serverDoc.draftData.serviceName
      : serverDoc.draftData.service,
    serviceDate: moment(serverDoc.draftData?.serviceDate).format("M/D/YYYY"),
    serviceDateStr: moment(serverDoc.draftData.serviceDate).format("M/D/YYYY"),
    serviceDateObj: new Date(serverDoc.draftData.serviceDate),
    utcDateCreatedStr: convertFromUtcDateToDateOnly(serverDoc.utcDateCreated),
    utcDateModifiedStr: convertFromUtcDateToDateOnly(serverDoc.utcDateModified),
    utcTimeModifiedStr: convertFromUtcDateTime(serverDoc.utcDateModified),
    utcExpectedDeletionDateStr: convertFromUtcDateToDateOnly(serverDoc.expectedDeletionDate),
    utcDateCreatedObj: new Date(
      convertFromUtcDateToDateOnly(serverDoc.utcDateCreated)
    ),
  };
}

export function convertTimeToLocally(data) {
  return formatTime(data);
}

export function convertServerDocumentToLocal(serverDoc) {
  return parseDocumentListItem(serverDoc);
}

export function convertServerDocumentDraftToLocal(serverDoc) {
  return parseDocumentDraftListItem(serverDoc);
}
export function totHoursAndMinutes(totalMinutes) {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  var hoursStr = hours > 0 ? `${hours} hr ` : "";
  var minutesStr = minutes > 0 ? `${minutes} mins` : "";
  const formatedTime = `${hoursStr}${minutesStr}`;
  return formatedTime;
}
