import { all, fork } from 'redux-saga/effects';
import { darftCountSaga, docListSaga, trashDocSaga, unTrashDocSaga, staffDocumentChartSaga, clientDocumentChartSaga, BillingDocumentChartSaga, getDocumenByIdSaga, docBillingHistorySaga, checkComplianceSaga, getDocumenBillingActionSaga, getDocumentServiceListSaga, updateDocumentServiceSaga, getPlaceOfServiceSaga, updatePlaceOfServicePayloadSaga, getRiskDDLSaga, getRiskFactorDDLSaga, getRiskProtectiveDDLSaga, getLatestDocumentByTemplateIdSaga, getStaffListActiveInactiveSaga, BillingByPayerSaga, updateDocumentServiceDateSaga, updateDocumentDiagnosisSaga, updateDocumentSiteOfServiceSaga, metricsByStatusSaga, metricsByTemplateSaga, getDocSuperBillSaga, getDocTemplateTypeSaga } from './docs.Saga';
import { LoginSaga, getAuthViewTransSaga, getRoleSaga, getStaffSettingsSaga, } from './auth.Saga';
import { getClientListSaga, getServiceListSaga, getSiteListSaga, getStaffListSaga ,metricsByServiceCodeSaga} from './docs.Saga';
import { clinicLogoSaga } from './settings.Saga';
import { checkDuplicateClientSaga, clientBillingClaimSaga, completeComplianceSaga, deleteManuallyCompletedComplianceSaga, deleteReviewGoalSaga, getClientComplianceListSaga, getClientStatusSaga, getClinicFlagSaga, getMultiClientListSaga, getReviewGoalByGoalIdSaga, getTpStaffTypesDllSaga, getUnreadCountSaga, insertReviewGoalSaga, saveAssignFlagToClientSaga, saveSelectedClientEligibilitySaga, saveSelectedClientStatusSaga, getClientLedgerByClientIdSaga, insertClientLedgerRecordSaga, deleteClientLedgerRecordSaga, getPaymentTypesClientLedgerSaga, getClientLedgerByIdSaga, getCurrentLedgerBalanceSaga, updateClientLedgerSaga, getClientAuditSaga, getDocForComplianceSaga, getClientPortalAccountAccessSaga, createAccForCPSaga, updateAccForCPSaga, getAccCPDetailSaga, resetAccPwdSaga, deleteClientMedicationSaga, updateClientMedicationSaga, insertClientMedicationSaga, getClientMedicationByClientIdSaga, getClientMedicationByIdSaga,insertSocialDeterminantsSaga,getSocialDeterminantsSaga,updateSocialDeterminantsSaga} from './client.Saga';
import { AssignSetToClientSaga, AssignSetToStaffSaga, AvailableServiceListSaga, GetServiceDDLByClinicIdSaga, InsertServiceSetSaga, SaveDefaultGoalTemplatetSaga, UpdateServiceSetSaga, assignCliniTagsSaga, assignDefaultGoalsToClientSaga, createNewAuthSaga, deleteAuthSetSaga, deleteClinicDefaultGoalsSaga, deleteClinicDocumentSaga, deleteClinicDocumentTagSaga, deleteDefaultGoalTemplateSetSaga, deleteServiceSetSaga, getAuthSetListSaga, getClientComplianceByIdSaga, getClientComplianceForClinicDDLSaga, getClientComplianceSaga, getCliniTagsSaga, getClinicDefaultGoalsSaga, getClinicDefaultGoalsTemplatesSaga, getClinicDocumentByIdSaga, getClinicDocumentS3SignedUrlSaga, getClinicServiceListSaga, getClinicStoredDocumentByClinicSaga, getDocumentTemplateSaga, getGoalByIdSaga, getServiceSetsSaga, updateAuthSetSaga, updateClinicDefaultGoalsTemplateSaga, insertClinicianSaga, getClinicianListSaga, getClinicNotificationSettingSaga, saveClinicNotificationSettingPayloadSaga,getClinicNewAuthAlertSettingSaga, assignNotificationSettingPayloadSaga, storeComplianceReportDataSaga, getRoleListSaga, getAssignedRolesForNotificationSettingsSaga, getClinicTpServiceTypesSaga, getClinicTpStaffTypesSaga, insertOrUpdateClinicTpServiceTypesSaga, deleteClinicTpServiceTypesSaga, insertOrUpdateClinicTpStaffTypesSaga, deleteClinicTpStaffTypesSaga,AssignCertificateToStaffSaga,getCalenderCategorySaga,insertCalendarCategorySaga,updateCalendarCategorySaga,deleteCalendarCategorySaga,getDefaultCalendarCategoriesSaga,updateDefaultCalendarCategoriesSaga} from './clinic.saga';

import { billingClaimServiceLineSaga, getEdiByBatchIdSaga, getbillingTransItemClaimIdSaga } from './billing.Saga';
import { getBillingCountSaga, getClientByGenderCountSaga, getClientCountSaga, getClockStatusSaga, getServiceUnitsRenderSaga, getTimeClockByIdSaga, saveTaskFilterSaga, updateTImeClockSaga, updateTimeClockByIdSaga, getTimeClockArchiveByIdSaga,getTrainingLinksSaga ,getUpcomingAppointmentsSaga,getUpcomingComplianceCountDataSaga, getInsuranceTypesSaga} from './dashboard.Saga';

import { GetServiceSetDDLSaga, GetStaffNotificationSettingSaga,GetStaffNewNotificationSettingSaga, SaveStaffNotificationSaga, GetStaffNotificationMediumSettingSaga, SaveStaffNotificationMediumSaga,SaveStaffCredentialInfoSaga,DeleteStaffCredentialSaga ,GetStaffCredentialListSaga,updateStaffCredentialSaga, getStaffAuditLogSaga, assignAdditionalClientsToStaffSaga, assignStaffToSiteSaga, exportCaseloadReportToExcelSaga} from './staff.Saga';
import { getComplianceReportSaga } from './compliance.saga';
import { storeComplianceReport } from '../actions';
import { LockUnlockDocumentsSaga } from './docs.Saga';
import { ApproveOrDisapproveDocumentsSaga } from './docs.Saga';
import { applyDocSignatureSaga, deleteAttachmentSaga, getAllSkillsSaga, getDocTemplatePrefsSaga, getStaffDocAttachmentSaga, getStaffDocByIdSaga, getStaffDocListSaga, getStaffDocSaga, getStaffDocSignatureSaga, getStaffGoalsSaga, getStaffTemplatesSaga, insertDocSaga, updateDocSaga } from './staffDocs.Saga';
import { deleteServiceRuleSaga, getServiceRuleListSaga, insertServiceRuleSaga, updateServiceRuleSaga } from './service.Saga';
import { getAuditLogSaga, getClinicReminderSettingsSaga, getClinicsSitesSaga, getGlobalClinicsSitesSaga, insertPhoneSettingSaga, insertReminderEmailSettingsSaga, insertReminderSettingsSaga, insertTextSettingSaga } from './clinic.reminders.saga';


export function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(getStaffSettingsSaga),
    fork(getRoleSaga),
    fork(darftCountSaga),
    fork(trashDocSaga),
    fork(unTrashDocSaga),
    fork(docListSaga),
    fork(clinicLogoSaga),
    fork(staffDocumentChartSaga),
    fork(clientDocumentChartSaga),
    fork(BillingDocumentChartSaga),
    fork(getClientListSaga),
    fork(getStaffListSaga),
    fork(getServiceListSaga),
    fork(getSiteListSaga),
    fork(clientBillingClaimSaga),
    fork(getDocumenByIdSaga),
    fork(getClientComplianceSaga),
    fork(getClinicServiceListSaga),
    fork(getDocumentTemplateSaga),
    fork(getClientComplianceForClinicDDLSaga),
    fork(billingClaimServiceLineSaga),
    fork(getClientComplianceByIdSaga),
    fork(billingClaimServiceLineSaga),
    fork(docBillingHistorySaga),
    fork(getClientComplianceListSaga),
    fork(completeComplianceSaga),
    fork(deleteManuallyCompletedComplianceSaga),
    fork(checkComplianceSaga),
    fork(getClinicFlagSaga),
    fork(saveAssignFlagToClientSaga),
    fork(getClientStatusSaga),
    fork(saveSelectedClientStatusSaga),
    fork(saveSelectedClientEligibilitySaga),
    fork(getUnreadCountSaga),
    fork(getMultiClientListSaga),
    fork(getbillingTransItemClaimIdSaga),
    fork(getClinicDefaultGoalsSaga),
    fork(deleteClinicDefaultGoalsSaga),
    fork(assignDefaultGoalsToClientSaga),
    fork(getGoalByIdSaga),
    fork(getClientCountSaga),
    fork(getServiceSetsSaga),
    fork(deleteServiceSetSaga),
    fork(InsertServiceSetSaga),
    fork(AssignSetToStaffSaga),
    fork(UpdateServiceSetSaga),
    fork(AvailableServiceListSaga),
    // fork(getDiagnosisCountSaga),
    fork(getServiceUnitsRenderSaga),
    fork(getBillingCountSaga),
    fork(GetServiceDDLByClinicIdSaga),
    fork(GetServiceSetDDLSaga),
    fork(getComplianceReportSaga),
    fork(getAuthSetListSaga),
    fork(deleteAuthSetSaga),
    fork(createNewAuthSaga),
    fork(updateAuthSetSaga),
    fork(getDocumenBillingActionSaga),
    fork(SaveDefaultGoalTemplatetSaga),
    fork(getClinicDefaultGoalsTemplatesSaga),
    fork(deleteDefaultGoalTemplateSetSaga),
    fork(updateClinicDefaultGoalsTemplateSaga),
    fork(AssignSetToClientSaga),
    fork(getClinicStoredDocumentByClinicSaga),
    fork(getClinicDocumentS3SignedUrlSaga),
    fork(deleteClinicDocumentSaga),
    fork(deleteClinicDocumentTagSaga),
    fork(getClinicDocumentByIdSaga),
    fork(getCliniTagsSaga),
    fork(assignCliniTagsSaga),
    fork(insertClinicianSaga),
    fork(getClinicianListSaga),
    fork(saveTaskFilterSaga),
    fork(getTpStaffTypesDllSaga),
    fork(getEdiByBatchIdSaga),
    fork(insertReviewGoalSaga),
    fork(getReviewGoalByGoalIdSaga),
    fork(deleteReviewGoalSaga),
    fork(GetStaffNotificationSettingSaga),
    fork(GetStaffNewNotificationSettingSaga),
    fork(SaveStaffNotificationSaga),
    fork(getClinicNotificationSettingSaga),
    fork(saveClinicNotificationSettingPayloadSaga),
    fork(getClinicNewAuthAlertSettingSaga),
    fork(getAuthViewTransSaga),
    fork(getClockStatusSaga),
    fork(updateTImeClockSaga),
    fork(assignNotificationSettingPayloadSaga),
    fork(getRoleListSaga),
    fork(storeComplianceReport),
    fork(storeComplianceReportDataSaga),
    fork(getDocumentServiceListSaga),
    fork(updateDocumentServiceSaga),
    fork(updateDocumentServiceDateSaga),
    fork(updateDocumentDiagnosisSaga),
    fork(updateDocumentSiteOfServiceSaga),
    fork(getPlaceOfServiceSaga),
    fork(updatePlaceOfServicePayloadSaga),
    fork(getRiskDDLSaga),
    fork(GetStaffNotificationMediumSettingSaga),
    fork(SaveStaffNotificationMediumSaga),
    fork(getAssignedRolesForNotificationSettingsSaga),
    fork(getTimeClockByIdSaga),
    fork(updateTimeClockByIdSaga),
    fork(getClientByGenderCountSaga),
    fork(getLatestDocumentByTemplateIdSaga),
    fork(getRiskFactorDDLSaga),
    fork(getRiskProtectiveDDLSaga),
    fork(getStaffListActiveInactiveSaga),
    fork(getClinicTpServiceTypesSaga),
    fork(getClinicTpStaffTypesSaga),
    fork(insertOrUpdateClinicTpServiceTypesSaga),
    fork(deleteClinicTpServiceTypesSaga),
    fork(insertOrUpdateClinicTpStaffTypesSaga),
    fork(deleteClinicTpStaffTypesSaga),
    fork(BillingByPayerSaga),
    fork(checkDuplicateClientSaga),
    fork(LockUnlockDocumentsSaga),
    fork(ApproveOrDisapproveDocumentsSaga),
    fork(getTimeClockArchiveByIdSaga),
    fork(AssignCertificateToStaffSaga),
    fork(AssignCertificateToStaffSaga),
    fork(getStaffTemplatesSaga),
    fork(getStaffDocListSaga),
    fork(getDocTemplatePrefsSaga),
    fork(getStaffGoalsSaga),
    fork(getAllSkillsSaga),
    fork(insertDocSaga),
    fork(getStaffDocSaga),
    fork(getStaffDocByIdSaga),
    fork(getStaffDocSignatureSaga),
    fork(getStaffDocAttachmentSaga),
    fork(updateDocSaga),
    fork(applyDocSignatureSaga),
    fork(getTrainingLinksSaga),
    fork(getCalenderCategorySaga),
    fork(insertCalendarCategorySaga),
    fork(updateCalendarCategorySaga),
    fork(deleteCalendarCategorySaga),
    fork(getDefaultCalendarCategoriesSaga),
    fork(updateDefaultCalendarCategoriesSaga),
    fork(metricsByStatusSaga),
    fork(metricsByTemplateSaga),
    fork(deleteAttachmentSaga),
    fork(getServiceRuleListSaga),
    fork(deleteServiceRuleSaga),
    fork(insertServiceRuleSaga),
    fork(updateServiceRuleSaga),
    fork(getUpcomingAppointmentsSaga),
    fork(getUpcomingComplianceCountDataSaga),
    fork(metricsByServiceCodeSaga),
    fork(getDocSuperBillSaga),
    fork(getClientLedgerByClientIdSaga),
    fork(insertClientLedgerRecordSaga),
    fork(deleteClientLedgerRecordSaga),
    fork(getPaymentTypesClientLedgerSaga),
    fork(getClientLedgerByIdSaga),
    fork(SaveStaffCredentialInfoSaga),
    fork(DeleteStaffCredentialSaga),
    fork(GetStaffCredentialListSaga),
    fork(updateStaffCredentialSaga),
    fork(getCurrentLedgerBalanceSaga),
    fork(updateClientLedgerSaga),
    fork(getStaffAuditLogSaga),
    fork(getClientAuditSaga),
    fork(assignAdditionalClientsToStaffSaga),
    fork(assignStaffToSiteSaga),
    fork(getDocForComplianceSaga),
    fork(getClinicsSitesSaga),
    fork(getClinicReminderSettingsSaga),
    fork(insertReminderSettingsSaga),
    fork(insertReminderEmailSettingsSaga),
    fork(insertPhoneSettingSaga),
    fork(insertTextSettingSaga),
    fork(getClientPortalAccountAccessSaga),
    fork(getAuditLogSaga),
    fork(createAccForCPSaga),
    fork(updateAccForCPSaga),
    fork(getAccCPDetailSaga),
    fork(resetAccPwdSaga),
    fork(deleteClientMedicationSaga),
    fork(updateClientMedicationSaga),
    fork(insertClientMedicationSaga),
    fork(getClientMedicationByClientIdSaga),
    fork(getClientMedicationByIdSaga),
    fork(exportCaseloadReportToExcelSaga),
    fork(getDocTemplateTypeSaga),
    fork(getInsuranceTypesSaga),
    fork(insertSocialDeterminantsSaga),
    fork(getSocialDeterminantsSaga),
    fork(updateSocialDeterminantsSaga),
    fork(getGlobalClinicsSitesSaga)
  ]);
}
